.trends {
  &__header {
    color: var(--primary-text-color);
    background: var(--brand-color--faint);
    border-bottom: 1px solid var(--brand-color--med);
    font-weight: 500;
    padding: 15px;
    font-size: 16px;
    cursor: default;

    .fa {
      display: inline-block;
      margin-right: 5px;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    padding: 15px;
    border-bottom: 1px solid var(--brand-color--med);

    &:last-child {
      border-bottom: 0;
    }

    &__name {
      flex: 1 1 auto;
      color: var(--primary-text-color);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 13px;

      strong {
        font-weight: bold;
      }

      a {
        color: var(--primary-text-color--faint);
        text-decoration: none;
        font-size: 14px;
        font-weight: 500;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &:hover,
        &:focus,
        &:active {
          span {
            text-decoration: underline;
          }
        }
      }
    }

    &__current {
      flex: 0 0 auto;
      width: 100px;
      font-size: 24px;
      line-height: 36px;
      font-weight: 500;
      text-align: center;
      color: var(--primary-text-color--faint);
    }

    &__sparkline {
      flex: 0 0 auto;
      width: 50px;

      path {
        stroke: var(--highlight-text-color) !important;
      }
    }
  }
}
