// Truth Colors
$color-1: #c62828;
$color-1-dark: #8e0000;
$color-1-light: #ff5f52;
$color-2: #e53935;
$color-2-dark: #ab000d;
$color-2-light: #ff6f60;
$color-3: #1a237e;
$color-3-dark: #000051;
$color-3-light: #534bae;
$color-4: #3949ab;
$color-4-dark: #00227b;
$color-4-light: #6f74dd;
$color-5: #37474f;
$color-5-dark: #102027;
$color-5-light: #62727b;
$color-6: #f5f5f5;
$color-6-dark: #c2c2c2;
$color-6-light: #fff;
$color-7: #00e676;
$color-7-dark: #00b248;
$color-7-light: #66ffa6;
$color-8: #ffea00;
$color-8-dark: #c7b800;
$color-8-light: #ffff56;
$color-9: #ffab00;
$color-9-dark: #c67c00;
$color-9-light: #ffdd4b;

// BREAKPOINT SETS

// navigation breakpoints - by default show all elements and link names along with icons

// turns navigation links into icon-only buttons
$nav-breakpoint-1: 850px;
// search field hidden and replaced with search icon link
$nav-breakpoint-2: 650px;
// "Post" button hidden and replaced with floating button on bottom corner
$nav-breakpoint-3: 450px;
// Site Logo hidden - bare minimum navigation for smallest width devices
$nav-breakpoint-4: 375px;

// Commonly used web colors

$success-green: #79bd9a !default; // Padua
$error-red: #df405a !default; // Cerise
$warning-red: #ff5050 !default; // Sunset Orange
$gold-star: #ca8f04 !default; // Dark Goldenrod

// Variables for defaults in UI
$base-shadow-color: #000 !default;
$base-overlay-background: #000 !default;
$valid-value-color: $success-green !default;
$error-value-color: $error-red !default;

// Language codes that uses CJK fonts
/* stylelint-disable-next-line value-keyword-case -- locale filenames */
$cjk-langs: ja, ko, zh-CN, zh-HK, zh-TW;

// Variables for components
$media-modal-media-max-width: 100%;
// put margins on top and bottom of image to avoid the screen covered by image.
$media-modal-media-max-height: 80%;

$no-gap-breakpoint: 415px;

// CSS variables
// NOTE: Prefer CSS variables whenever possible.
// They're future-proof and more flexible.
:root {
  --thumb-navigation-base-height: 60px;
  --thumb-navigation-height: calc(
    var(--thumb-navigation-base-height) + env(safe-area-inset-bottom)
  );
}
