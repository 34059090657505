.explanation-box {
  padding: 5px 20px;
}

.accordion {
  @apply text-black dark:text-white bg-gray-100 dark:bg-slate-900;
  padding: 15px 20px;
  font-size: 14px;
  border-radius: 8px;
  margin: 0;
  position: relative;

  &__title {
    font-weight: bold !important;
    font-size: 16px !important;
    background: transparent !important;
    color: var(--primary-text-color) !important;
    padding: 0 !important;
    margin: 0 !important;
    text-transform: none !important;
    text-align: left !important;
    display: flex !important;
    align-items: center;
    border: 0;
    width: 100%;

    &::after {
      @apply text-black dark:text-white;
      content: '';
      display: block;
      font-family: 'Font Awesome 5 Free';
      font-weight: 900;
      font-size: 0.8em;
      padding-left: 0.6em;
      margin-left: auto;
    }
  }

  &__menu {
    position: absolute;
    top: 17px;
    right: 40px;
  }

  &__content {
    height: 0;
    overflow: hidden;
  }

  &--expanded &__title {
    margin-bottom: 10px !important;

    &::after {
      @apply text-black dark:text-white;
      content: '';
    }
  }

  &--expanded &__content {
    height: auto;
  }

  a {
    color: var(--brand-color--hicontrast);
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}
