.emoji-react {
  @apply inline-block text-gray-900 dark:text-gray-300 no-underline;
  transition: 0.2s;

  &__emoji {
    img {
      @apply w-5 h-5;
      filter: drop-shadow(2px 0 0 var(--foreground-color));
    }
  }

  &__count {
    @apply hidden;
  }

  + .emoji-react {
    @apply -mr-3;
  }

  &[type='button'] {
    cursor: pointer;
  }
}

.emoji-reacts {
  display: inline-flex;
  flex-direction: row-reverse;
}

.emoji-reacts-container {
  display: inline-flex;

  &:hover {
    .emoji-react {
      margin: 0;

      &__count {
        display: inline;
      }
    }

    .emoji-reacts__count {
      display: none;
    }
  }
}

.emoji-react-selector {
  position: absolute;
  display: flex;
  background-color: var(--foreground-color);
  padding: 5px 8px;
  border-radius: 9999px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
  opacity: 0;
  pointer-events: none;
  transition: 0.1s;
  z-index: 999;

  &--visible,
  &--focused {
    opacity: 1;
    pointer-events: all;
  }

  &__emoji {
    display: block;
    padding: 0;
    margin: 0;
    border: 0;
    background: transparent;

    img {
      width: 36px;
      height: 36px;
      padding: 3px;
      transition: 0.1s;
    }

    &:hover,
    &:focus {
      img {
        transform: scale(1.2);
      }
    }
  }
}

.detailed-status__wrapper .emoji-react-selector {
  bottom: 40px;
  right: 10px;
}

.status .emoji-react-selector {
  bottom: 100%;
  left: -20px;

  @media (max-width: 455px) {
    bottom: 31px;
    right: 10px;
    left: auto;
  }
}
