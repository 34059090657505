.radio-button {
  @apply text-black dark:text-white;
  font-size: 14px;
  position: relative;
  display: inline-block;
  padding: 6px 0;
  line-height: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;

  input[type=radio],
  input[type=checkbox] {
    display: none;
  }

  &__input {
    @apply inline-block relative box-border border border-solid border-primary-600;
    width: 18px;
    height: 18px;
    flex: 0 0 auto;
    margin-right: 10px;
    top: -1px;
    border-radius: 50%;
    vertical-align: middle;

    &.checked {
      @apply bg-primary-600;
    }

    &:active,
    &:focus,
    &:hover {
      @apply border-4;
    }
  }
}
