.poll {
  margin-top: 16px;
  font-size: 14px;

  li {
    margin-bottom: 10px;
    position: relative;
  }

  &__text {
    position: relative;
    display: flex;
    padding: 6px 0;
    line-height: 18px;
    cursor: default;
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
    color: var(--primary-text-color--faint);

    input[type=radio],
    input[type=checkbox] {
      display: none;
    }

    > span:last-child {
      flex: 1;
    }

    .autossugest-input {
      flex: 1 1 auto;
    }

    input[type=text] {
      @apply border border-solid border-primary-600;
      display: block;
      box-sizing: border-box;
      width: 100%;
      font-size: 14px;
      outline: 0;
      font-family: inherit;
      border-radius: 4px;
      padding: 6px 10px;

      &:focus {
        @apply border border-solid border-primary-500;
      }
    }

    &.selectable {
      cursor: pointer;
    }

    &.editable {
      display: flex;
      align-items: center;
      overflow: visible;

      .autosuggest-input {
        width: 100%;
      }
    }
  }

  &__input {
    @apply border border-solid border-primary-600;
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    width: 18px;
    height: 18px;
    flex: 0 0 auto;
    margin-right: 10px;
    top: -1px;
    border-radius: 50%;
    vertical-align: middle;

    &.checkbox {
      border-radius: 4px;
    }

    &.active {
      border-color: $valid-value-color;
      background: $valid-value-color;
    }

    &:active,
    &:focus,
    &:hover {
      border-width: 4px;
      background: none;
    }

    &::-moz-focus-inner {
      outline: 0 !important;
      border: 0;
    }

    &:focus,
    &:active {
      outline: 0 !important;
    }
  }

  .button {
    height: 36px;
    padding: 0 16px;
    margin-right: 10px;
    font-size: 14px;
  }

  &__cancel {
    @apply h-5;

    .svg-icon {
      @apply h-5 w-5;
    }
  }
}

.muted .poll {
  color: var(--primary-text-color);

  &__chart {
    background: hsla(var(--brand-color_hsl), 0.2);

    &.leading {
      background: hsla(var(--brand-color_hsl), 0.2);
    }
  }
}
