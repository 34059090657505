.reply-mentions {
  @apply text-gray-500 dark:text-gray-400 mb-1 text-sm;

  &__account {
    @apply text-primary-600 dark:text-primary-400 hover:text-primary-800 dark:hover:text-primary-300 no-underline hover:underline;
  }
}

.status__wrapper,
.detailed-status {
  .reply-mentions {
    display: block;
    margin: 4px 0 0 0;

    span {
      cursor: pointer;
    }
  }
}
