/**
 * iOS style loading spinner.
 * Adapted from: https://loading.io/css/
 * With some help scaling it: https://signalvnoise.com/posts/2577-loading-spinner-animation-using-css-and-webkit
 */

.spinner {
  @apply inline-block relative w-20 h-20;
}

.spinner > div {
  @apply absolute origin-[50%_50%] w-full h-full;
  animation: spinner 1.2s linear infinite;
}

.spinner > div::after {
  @apply block absolute rounded-full bg-gray-600;
  content: ' ';
  top: 3.75%;
  left: 46.25%;
  width: 7.5%;
  height: 22.5%;
}

.spinner > div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}

.spinner > div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}

.spinner > div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}

.spinner > div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}

.spinner > div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}

.spinner > div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}

.spinner > div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}

.spinner > div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}

.spinner > div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}

.spinner > div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}

.spinner > div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}

.spinner > div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}

@keyframes spinner {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
