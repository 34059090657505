.status__display-name,
.detailed-status__display-name,
.account__display-name {
  text-decoration: none;
}

.status__display-name,
.account__display-name {
  strong {
    @apply text-gray-800 dark:text-gray-200;
  }
}

.muted {
  .emojione {
    opacity: 0.5;
  }
}

.status__display-name,
.detailed-status__display-name,
a.account__display-name {
  &:hover strong {
    text-decoration: underline;
  }
}

.account__display-name strong {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.display-name {
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;

  bdi {
    min-width: 0;
  }
}

.display-name__html {
  font-weight: 600;
  padding-right: 4px;
}

.display-name__account {
  font-size: 14px;
}
