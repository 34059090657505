.react-datepicker {
  @apply dark:bg-slate-900 dark:border-slate-700 p-4 font-sans text-xs text-gray-900 dark:text-gray-300 border border-solid border-gray-200 rounded-lg;
}

.react-datepicker__input-container > input {
  @apply dark:bg-slate-800 dark:text-white block w-full sm:text-sm border-gray-300 dark:border-gray-600 rounded-md focus:ring-indigo-500 focus:border-indigo-500;

  &.has-error {
    @apply text-red-600 border-red-600;
  }
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  @apply border-b-white dark:border-b-slate-900;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
  @apply border-b-gray-200 dark:border-b-slate-700;
}

.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-top-right-radius: var(--border-radius-lg);
}

.react-datepicker__header {
  @apply bg-white dark:bg-slate-900 border-b-0 py-1 px-0;
  // border-top-left-radius: var(--border-radius-lg);
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  @apply text-gray-900 dark:text-gray-300 font-bold text-sm;
}

.react-datepicker__current-month {
  margin-top: 2px;
}

.react-datepicker__navigation {
  @apply top-4 h-8 w-8 rounded hover:bg-gray-50 dark:hover:bg-slate-900/50;
}

.react-datepicker__navigation-icon {
  margin-top: -3px;
}

.react-datepicker__navigation-icon--previous::before {
  right: -5px;
}

.react-datepicker__navigation-icon--next::before {
  left: -5px;
}

.react-datepicker__navigation--previous {
  left: 16px;
}

.react-datepicker__navigation--next {
  right: 16px;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
  border-width: 2px 2px 0 0;
  height: 7px;
  width: 7px;
  top: 8px;
}

.react-datepicker__header__dropdown {
  @apply py-4;
}

.react-datepicker__day-names,
.react-datepicker__week {
  @apply flex justify-between;
}

.react-datepicker__time {
  @apply dark:bg-slate-900;
}

.react-datepicker__time-container {
  @apply dark:border-slate-700;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  @apply text-gray-900 dark:text-gray-300;
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.react-datepicker__time-list-item--disabled,
.react-datepicker__day--disabled {
  @apply text-gray-400 dark:text-gray-500;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover,
.react-datepicker__time-list-item:hover {
  @apply bg-gray-100 dark:bg-slate-700 rounded;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  @apply bg-primary-600 hover:bg-primary-700 dark:bg-slate-300 dark:hover:bg-slate-200 text-white dark:text-black rounded;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  @apply bg-primary-50 hover:bg-primary-100 dark:bg-slate-700 dark:hover:bg-slate-600 text-primary-600 dark:text-primary-400;
}
