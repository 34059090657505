.wtf-panel {
  @include standard-panel;
  display: flex;
  width: 100%;
  flex-direction: column;
  height: auto;
  box-sizing: border-box;

  @media screen and (max-width: 580px) {
    border-radius: 0;
  }

  &:first-child {
    margin-top: 0;
  }

  &:not(:last-of-type) {
    margin-bottom: 10px;
  }

  .wtf-panel-header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding: 15px 15px 0;

    &__icon {
      margin-right: 10px;
      font-size: 20px;

      &.svg-icon {
        width: 20px;
        height: 20px;
      }
    }

    &__label {
      flex: 1 1;
      color: var(--primary-text-color);
      font-size: 16px;
      font-weight: bold;
      line-height: 19px;
    }
  }

  &__content {
    width: 100%;
    padding-top: 8px;
  }

  &__list {
    padding: 0 5px;
  }

  &__expand-btn {
    @apply border-gray-300 dark:border-gray-600;
    display: block;
    width: 100%;
    height: 100%;
    max-height: 46px;
    position: relative;
    border-top: 1px solid;
    transition: max-height 150ms ease;
    overflow: hidden;
    opacity: 1;
    text-align: center;
    line-height: 46px;
    font-size: 14px;
    cursor: pointer;
    color: var(--primary-text-color);
    text-decoration: none;
  }
}
