.aliases {
  &__accounts {
    overflow-y: auto;

    .account__display-name {
      &:hover strong {
        text-decoration: none;
      }
    }

    .account__avatar {
      cursor: default;
    }

    &.empty-column-indicator {
      min-height: unset;
      overflow-y: unset;
    }
  }
}

.aliases-settings-panel {
  flex: 1;

  .item-list article {
    border-bottom: 1px solid var(--primary-text-color--faint);

    &:last-child {
      border-bottom: 0;
    }
  }

  .slist--flex {
    height: 100%;
  }
}
