.halloween,
.site-preview.halloween {
  // Set brand color to orange
  --brand-color_h: 29.727272727272727;
  --brand-color_s: 100%;
  --brand-color_l: 43.13725490196079%;

  // Stars BG
  background-color: #904700; // Color matches twinkle.svg
  background-image: url('../images/halloween/starfield.png');
  background-size: cover;
  background-attachment: fixed;
  background-position: center;

  // Full-screen pseudo-elements to hold BG graphics
  &::before,
  &::after,
  > .app-holder::before,
  > .app-holder::after {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;
    z-index: -100;
  }

  // Spiderweb BG
  &::before {
    background-image: url('../images/halloween/spiderweb.svg');
  }

  // Twinkle effect by masking with semi-transparent animated circles
  &::after {
    z-index: -101;
    background: transparent url("../images/halloween/twinkle.svg") repeat top center;
    animation: halloween-twinkle 200s linear infinite;
  }

  > .app-holder {
    // Vignette
    &::before {
      background-image: radial-gradient(
        circle,
        transparent 0%,
        transparent 60%,
        var(--vignette-color) 100%
      );
    }

    // Floating clouds BG
    &::after {
      background: transparent url("../images/halloween/clouds.png") repeat top center;
      animation: halloween-clouds 200s linear infinite;
    }

    // Dangling spider
    .ui .page__top::after,
    .ui .page__columns::after {
      content: '';
      display: block;
      width: 100px;
      height: 100px;
      right: 20px;
      background-image: url('../images/halloween/spider.svg');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: top right;
      z-index: -1;
      pointer-events: none;
    }

    .ui .page__columns::after {
      position: fixed;
      top: 50px;
    }

    .ui .page__top::after {
      position: absolute;
      bottom: -100px;
    }

    .ui .page__top + .page__columns::after {
      display: none;
    }

    .profile-info-panel {
      color: #fff;

      &-content__name h1 {
        span:first-of-type {
          color: hsla(0, 0%, 100%, 0.6);
        }

        small {
          color: #fff;
        }
      }

      &-content__bio {
        color: #fff;
      }

      &-content__bio a,
      &-content__fields a {
        color: hsl(
          var(--brand-color_h),
          var(--brand-color_s),
          calc(var(--brand-color_l) + 8%)
        );
      }
    }
  }

  .column-header > button.grouped,
  .column-header > .btn.grouped {
    color: #fff;

    &:hover {
      color: #fff;
    }

    &::before {
      background-color: var(--brand-color);
    }
  }
}

// Animations
@keyframes halloween-twinkle {
  from { background-position: 0 0; }
  to { background-position: -10000px 5000px; }
}

@keyframes halloween-clouds {
  from { background-position: 0 0; }
  to { background-position: 10000px 0; }
}
