@use 'sass:math';

// OpenDyslexic
@font-face {
  font-family: 'OpenDyslexic';
  src: url('../fonts/OpenDyslexic/OpenDyslexic-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'OpenDyslexic';
  font-weight: bold;
  src: url('../fonts/OpenDyslexic/OpenDyslexic-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'OpenDyslexic';
  font-weight: bold;
  font-style: italic;
  src: url('../fonts/OpenDyslexic/OpenDyslexic-Bold-Italic.woff2') format('woff2');
}

@font-face {
  font-family: 'OpenDyslexic';
  font-style: italic;
  src: url('../fonts/OpenDyslexic/OpenDyslexic-Italic.woff2') format('woff2');
}

// TYPEOGRAPHY MIXINS

// declare the font family using these shortcuts
@mixin font-inter { font-family: 'Inter', Arial, sans-serif !important; }

// Declare font weights as a numerical value in rendered output
// Prevents certain browsers which do not play nice with "light, medium" textual declarations
// Numeical values always work more consistently across browsers
// Each font-weight is linked with the @font-face declaration to the actual font file
@mixin font-weight($weight) {
  @if $weight == 'light' { font-weight: 300; }
  @if $weight == 'normal' { font-weight: 400; }
  @if $weight == 'medium' { font-weight: 500; }
  @if $weight == 'bold' { font-weight: 700; }
  @if $weight == 'extrabold' { font-weight: 800; }
}

// Use these mixins to define font-size and line-height
// html and body declaration allows developer to pass px value as argument
// Rendered css will default to "rem" and fall back to "px" for unsupported browsers
@mixin font-size($size) {
  $rem: math.div($size, 10);
  $px: $size;
  font-size: #{$px + "px"};
  font-size: #{$rem + "rem"};
}

@mixin line-height($size) {
  $rem: math.div($size, 10);
  $px: $size;
  line-height: #{$px + "px"};
  line-height: #{$rem + "rem"};
}

// Soapbox icon font
@font-face {
  font-family: 'soapbox';
  src: url('../fonts/soapbox/soapbox.eot?pryg6i');
  src: url('../fonts/soapbox/soapbox.eot?pryg6i#iefix') format('embedded-opentype'),
    url('../fonts/soapbox/soapbox.ttf?pryg6i') format('truetype'),
    url('../fonts/soapbox/soapbox.woff?pryg6i') format('woff'),
    url('../fonts/soapbox/soapbox.svg?pryg6i#soapbox') format('svg');
  font-weight: normal;
  font-style: normal;
}

.fa-fediverse::before {
  font-family: 'soapbox';
  content: "\e901";
}

.fa-spinster::before {
  font-family: 'soapbox';
  content: "\e900";
}
