.sub {
  border: 1px solid hsla(var(--primary-text-color_hsl), 0.5);
  padding: 20px;
  position: relative;
  border-radius: 4px;

  &__title {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.3rem;
    margin-bottom: 4px;
  }

  &__amount {
    font-size: 2rem;
    line-height: normal;
    margin: 4px 0 12px;
  }

  &__billing {
    font-size: 14px;
  }

  &__button {
    margin-top: 20px;
  }

  /* Status indicator */
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 15px;
    right: 13px;
    border-radius: 100%;
    background-color: transparent;
    height: 13px;
    width: 13px;
  }

  &--trialing,
  &--active {
    &::before {
      background-color: #0f0;
    }
  }

  &--cancelling::before {
    background-color: #ff0;
  }

  &--incomplete,
  &--incomplete_expired,
  &--past_due,
  &--canceled,
  &--unpaid {
    &::before {
      background-color: #f00;
    }
  }
}

.subs {
  .sub {
    border-radius: 0;

    & + .sub {
      border-top: 0;
    }

    &:first-child {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    &:last-child {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
}

body.admin {
  .actions {
    margin-top: 40px;
  }
}

.funding-panel {
  margin-top: 15px;

  strong {
    font-weight: bold;
  }

  &__ratio {
    font-size: 14px;
    margin-bottom: 8px;
  }

  &__description {
    margin-top: 14px;
    font-size: 15px;
  }

  .button {
    margin-top: 20px;
  }

  &__reached {
    color: var(--brand-color);
  }
}
