// Fonts from elsewhere
@import '~line-awesome/dist/font-awesome-line-awesome/css/all.css';
@import '~@fontsource/inter/200.css';
@import '~@fontsource/inter/300.css';
@import '~@fontsource/inter/400.css';
@import '~@fontsource/inter/500.css';
@import '~@fontsource/inter/700.css';
@import '~@fontsource/inter/900.css';

@import 'mixins';
@import 'themes';
@import 'variables';
@import 'fonts';
@import 'reset';
@import 'basics';
@import 'footer';
@import 'accounts';
@import 'boost';
@import 'loading';
@import 'ui';
@import 'polls';
// @import 'introduction';
@import 'emoji_picker';
@import 'about';
@import 'rtl';
@import 'accessibility';
@import 'donations';
@import 'dyslexic';
@import 'demetricator';
@import 'chats';
@import 'navigation';
@import 'placeholder';
@import 'autosuggest';

// COMPONENTS
@import 'components/buttons';
@import 'components/inputs';
@import 'components/dropdown-menu';
@import 'components/modal';
@import 'components/account-header';
@import 'components/compose-form';
@import 'components/sidebar-menu';
@import 'components/emoji-reacts';
@import 'components/status';
@import 'components/reply-mentions';
@import 'components/detailed-status';
@import 'components/media-gallery';
@import 'components/notification';
@import 'components/display-name';
@import 'components/columns';
@import 'components/search';
@import 'components/react-toggle';
@import 'components/still-image';
@import 'components/trends';
@import 'components/wtf-panel';
@import 'components/profile-media-panel';
@import 'components/profile-info-panel';
@import 'components/setting-toggle';
@import 'components/spoiler-button';
@import 'components/video-player';
@import 'components/audio-player';
@import 'components/profile_hover_card';
@import 'components/filters';
@import 'components/snackbar';
@import 'components/accordion';
@import 'components/server-info';
@import 'components/admin';
@import 'components/backups';
@import 'components/crypto-donate';
@import 'components/remote-timeline';
@import 'components/federation-restrictions';
@import 'components/aliases';
@import 'components/icon';
@import 'components/radio-button';
@import 'components/directory';

// Holiday
@import 'holiday/halloween';

// Truth custom styles
@import 'truth';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .shadow-inset {
    box-shadow: inset 0 0 0 1px rgb(255 255 255 / 10%);
  }

  .d-screen {
    height: 100vh; // Backwards compatibility
    /* stylelint-disable-next-line unit-no-unknown */
    height: 100dvh;
  }
}

@import 'forms';
@import 'utilities';
@import 'components/datepicker';
