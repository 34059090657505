.account__header__subscribe {
  position: absolute;
  top: 10px;
  right: 10px;
  right: max(10px, env(safe-area-inset-right));
  z-index: 1;

  .subscription-button {
    color: var(--primary-text-color);
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--background-color);
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 500;
    padding: 4px;
    border-radius: 4px;
    opacity: 0.7;

    &:active,
    &:focus,
    &:hover {
      opacity: 1;
    }

    &:not(.button-active) i.fa {
      margin: 0;
    }

    .svg-icon {
      width: 20px;
      height: 20px;
    }
  }
}

.account__header__content {
  color: var(--primary-text-color--faint);
  font-size: 14px;
  font-weight: 400;
  overflow: hidden;
  word-break: normal;
  word-wrap: break-word;

  p {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    color: inherit;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}
