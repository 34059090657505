// THEME MIXINS

// standard container drop shadow
@mixin standard-panel-shadow {
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
}

// common properties for all standard containers
@mixin standard-panel {
  @include standard-panel-shadow;
  border-radius: 10px;
  background: var(--foreground-color);
}

// SHORTCUTS
@mixin input-placeholder($color) {
  &::-webkit-input-placeholder { color: $color; }
  &::-moz-placeholder { color: $color; }
  &:-ms-input-placeholder { color: $color; }
  &:-moz-placeholder { color: $color; }
}

@mixin avatar-radius {
  border-radius: 50%;
  background: transparent no-repeat;
  background-position: 50%;
  background-clip: padding-box;
}

@mixin avatar-size($size: 48px) {
  width: $size;
  height: $size;
  background-size: $size $size;
}

@mixin search-input {
  @include font-size(16);
  @include line-height(19);
  outline: 0;
  box-sizing: border-box;
  width: 100%;
  box-shadow: none;
  font-family: inherit;
  background: var(--background-color);
  color: var(--highlight-text-color);
  margin: 0;
  border-radius: 999px;
  border: 0;
  padding-left: 15px;

  // Chrome does not like these concatinated together
  &::placeholder { color: var(--primary-text-color--faint); }
  &:-ms-input-placeholder { color: var(--primary-text-color--faint); }
  &::-ms-input-placeholder { color: var(--primary-text-color--faint); }

  &::-moz-focus-inner {
    border: 0;
  }

  &::-moz-focus-inner,
  &:focus,
  &:active {
    outline: 0 !important;
  }
}
