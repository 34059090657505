.audio-player {
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
  background: $base-shadow-color;
  border-radius: 10px;
  padding-bottom: 44px;
  direction: ltr;

  &.editable {
    border-radius: 0;
    height: 100%;
  }

  .video-player__volume::before,
  .video-player__seek::before {
    background: currentColor;
    opacity: 0.15;
  }

  .video-player__seek__buffer {
    background: currentColor;
    opacity: 0.2;
  }

  .video-player__buttons button {
    color: currentColor;
    opacity: 0.75;

    &:active,
    &:hover,
    &:focus {
      color: currentColor;
      opacity: 1;
    }
  }

  .video-player__time-sep,
  .video-player__time-total,
  .video-player__time-current {
    color: currentColor;
  }

  .video-player__seek::before,
  .video-player__seek__buffer,
  .video-player__seek__progress {
    top: 0;
  }

  .video-player__seek__handle {
    top: -4px;
  }

  .video-player__controls {
    padding-top: 10px;
    background: transparent;
  }
}

.media-spoiler-audio {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  margin-top: 8px;
  position: relative;
  border: 0;
  display: block;
}
